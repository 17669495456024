import { apolloClient as graphql } from '@/apolloProvider'
import Logger from '../../../plugins/logger'
import Metrics from '../../../plugins/metrics'

import {
  GET_REPORTS,
  GET_REPORT,
  GET_REPORT_ASSIGNEES,
  GET_REPORT_MISCONDUCTS,
  GET_RELATED_REPORTS,
  GET_REPORT_CODE,
} from '@/graphql/queries'

import {
  ASSIGN_REPORT_PERMISSION,
  REVOKE_REPORT_PERMISSION,
  CREATE_AGREEMENT,
  SOLVE_REPORT,
  UPDATE_STATUS_REPORT,
  ASSIGN_REPORT_MISCONDUCTS,
  UNASSIGN_REPORT_MISCONDUCTS,
  CREATE_REPORT,
  CREATE_REPORT_ADDITIONAL_DATA,
  ASSIGN_REPORT_AREAS,
  UNASSIGN_REPORT_AREAS,
  ATTACH_REPORT_INVOLVED,
  DETACH_REPORT_INVOLVED,
  UPDATE_CRITICALITY_LEVEL,
  MOVE_REPORTS_TO_GROUPS,
  REMOVE_REPORTS_FROM_GROUPS,
  UPDATE_COMPANY_BRANCH_REPORT,
} from '@/graphql/mutations'
import { getFilterEventTitle } from '@/utils/reports'

export default {
  getReports: async (
    { commit, dispatch, state },
    {
      page,
      sources,
      misconductLocaleKeys,
      companyBranches,
      criticalLevels,
      companyBranchDetails,
      areas,
      statuses,
      term,
      sortBy,
      perPage,
      groups,
    },
  ) => {
    commit('setLoading', true)

    if (!state.companyId) {
      await dispatch('getCompanyByTenant')
    }
    const filters = {
      sources,
      misconductLocaleKeys,
      companyBranches,
      criticalLevels,
      areas,
      term,
      groups,
    }
    const variablesKeys = Object.keys(filters)

    variablesKeys.forEach((key) => {
      if (!filters[key] || !filters[key].length) return
      const title = getFilterEventTitle(key)
      Metrics.track(title, { value: filters[key] })
    })

    try {
      const {
        data: { reports = [] },
      } = await graphql.query({
        query: GET_REPORTS,
        variables: {
          page,
          sources,
          misconductLocaleKeys,
          companyBranches,
          areas,
          statuses,
          criticalLevels,
          term,
          sortBy,
          perPage,
          groups,
          companyBranchDetails,
        },
        fetchPolicy: 'no-cache',
      })

      commit('setReports', reports.data)

      return reports
    } catch (err) {
      commit('setError', `GRAPHQL_ERRORS.${err.message}`)
      commit('setReports', [])

      return { data: [], pagination: {} }
    } finally {
      await dispatch('getUnreadMessages')
      dispatch('getCounters')
      commit('setLoading', false)
    }
  },

  getReport: async ({ commit }, reportId) => {
    commit('setLoading', true)

    const {
      data: {
        reportByIdOnlyAdmin: { report },
      },
    } = await graphql
      .query({
        query: GET_REPORT,
        variables: { id: reportId },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))
      .finally(() => commit('setLoading', false))

    commit('mutate', { property: 'code', value: report.code })
    commit('mutate', {
      property: 'resolutionNote',
      value: report.resolutionNote,
    })
    commit('setReport', report)

    return report
  },

  getReportAssignees: async ({ commit }, reportId) => {
    const {
      data: { reportAssignees },
    } = await graphql
      .query({
        query: GET_REPORT_ASSIGNEES,
        variables: { id: reportId },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))

    commit('setReportAssignees', reportAssignees)

    return reportAssignees
  },

  getReportMisconducts: async ({ commit }, reportId) => {
    const {
      data: { reportAdminMisconducts },
    } = await graphql
      .query({
        query: GET_REPORT_MISCONDUCTS,
        variables: { id: reportId },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))

    commit('setAssignMisconducts', reportAdminMisconducts)
  },

  getRelatedReports: async ({ commit }, reportId) => {
    const {
      data: { relatedReportsByEmployees: response },
    } = await graphql
      .query({
        query: GET_RELATED_REPORTS,
        variables: { id: reportId },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))

    commit('mutate', { property: 'relatedReports', value: response })

    return response
  },

  updateStatusReport: async ({ dispatch }, { reportId: id, status }) => {
    const {
      data: { updateReportStatus: response },
    } = await graphql.mutate({
      mutation: UPDATE_STATUS_REPORT,
      variables: { id, status },
    })

    if (response?.id && status !== 'OPENED') {
      dispatch('activityLog', {
        reportId: id,
        message: `ACTIVITY_STATUS_${status}`,
        visible: true,
      })
    }
    return response
  },

  createAgreement: async ({ commit, dispatch }, { id: reportId, status }) => {
    await graphql
      .mutate({
        mutation: CREATE_AGREEMENT,
        variables: { id: reportId },
      })
      .then(async () => {
        await dispatch('activityLog', {
          reportId,
          message: 'ACTIVITY_STATUS_OPENED',
          visible: status === 'NEW',
        })
      })
      .catch((err) => commit('setGraphQLError', err))
  },

  solveReport: async ({ commit, dispatch }, { reportId: id, resolution }) => {
    const {
      data: { solveReport: response },
    } = await graphql
      .mutate({
        mutation: SOLVE_REPORT,
        variables: { id, resolution },
      })
      .catch((err) => commit('setGraphQLError', err))

    if (response?.id) {
      dispatch('activityLog', {
        reportId: id,
        message: 'ACTIVITY_STATUS_SOLVED',
        visible: true,
      })

      commit('mutate', {
        property: 'resolutionNote',
        value: response.resolutionNote,
      })
    }

    return response
  },

  assignReportPermission: async (
    { commit, dispatch },
    { reportId, employeeId, permission, assigneeName },
  ) => {
    const { data } = await graphql
      .mutate({
        mutation: ASSIGN_REPORT_PERMISSION,
        variables: { reportId, employeeId, permission },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))

    if (data?.assignReportPermission) {
      commit('setNotice', 'EMAIL_SENT_TO_ADMIN_WITH_PERMISSION')

      dispatch('activityLog', {
        reportId: reportId,
        message: `ACTIVITY_REPORT_ADD_ASSIGNEE_${permission}`,
        visible: false,
        additionalInformation: [assigneeName],
      })
    }
  },

  revokeReportPermission: async (
    { commit, dispatch },
    { reportId, assigneeId, assigneeName },
  ) => {
    await graphql
      .mutate({
        mutation: REVOKE_REPORT_PERMISSION,
        variables: { reportId, adminId: assigneeId },
      })
      .catch((err) => commit('setGraphQLError', err))

    commit('setNotice', 'EMAIL_SENT_TO_ADMIN_WITH_REVOKE_PERMISSION')

    dispatch('activityLog', {
      reportId: reportId,
      message: 'ACTIVITY_REPORT_ADD_ASSIGNEE_REVOKE',
      visible: false,
      additionalInformation: [assigneeName],
    })
  },

  assignReportMisconducts: async (
    { commit, dispatch },
    { reportId, misconducts },
  ) => {
    const misconductIds = misconducts.map((misconduct) => misconduct.id)

    const { data } = await graphql
      .mutate({
        mutation: ASSIGN_REPORT_MISCONDUCTS,
        variables: { id: reportId, misconducts: misconductIds },
      })
      .catch((err) => commit('setGraphQLError', err))

    if (data) {
      const additionalInformation = misconducts.map(
        ({ languages, localeKey }) => (languages ? languages : localeKey),
      )
      dispatch('activityLog', {
        reportId: reportId,
        message: 'ACTIVITY_REPORT_MISCONDUCT_ADDED',
        visible: false,
        additionalInformation,
      })
    }

    return data
  },

  unassignReportMisconducts: async (
    { state, commit, dispatch },
    { reportId, misconductId },
  ) => {
    const { data } = await graphql
      .mutate({
        mutation: UNASSIGN_REPORT_MISCONDUCTS,
        variables: { id: reportId, misconductId },
      })
      .catch((err) => commit('setGraphQLError', err))

    if (data) {
      const additionalInformation = state.misconducts
        .filter(({ id }) => id === misconductId)
        .map(({ languages, localeKey }) => (languages ? languages : localeKey))
      dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_MISCONDUCT_REMOVED',
        visible: false,
        additionalInformation,
      })
    }

    return data
  },

  getReportCode: async ({ commit }) => {
    commit('setLoading', true)

    const {
      data: { protocolize: code },
    } = await graphql
      .query({
        query: GET_REPORT_CODE,
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))

    commit('setLoading', false)
    return code
  },

  createReport: async ({ commit }, params) => {
    commit('setLoading', true)
    const { happenedAt, customInvolved } = params

    try {
      const sanitizedVariables = {
        ...params,
        customInvolved: customInvolved.filter((item) => item !== ''),
        happenedAt: happenedAt === 'NOT_SURE' ? null : happenedAt,
      }

      const response = await graphql.mutate({
        mutation: CREATE_REPORT,
        variables: sanitizedVariables,
      })

      const {
        data: { createReportByAdmin: report },
        errors,
      } = response

      if (report) {
        return { status: 'SUCCESS', report }
      } else {
        Logger.error(errors[0].message)
        commit('setError', 'Report Not Sent Error')
        return { status: 'ERROR', errors }
      }
    } catch (error) {
      Logger.error(error.message)
      commit('setError', error.message)
      commit('setLoading', false)
      return false
    }
  },

  createReportAdditionalData: async (
    { commit, dispatch },
    { reportId, params },
  ) => {
    try {
      const response = await graphql.mutate({
        mutation: CREATE_REPORT_ADDITIONAL_DATA,
        variables: { reportId, params },
      })

      const {
        data: { createReportAdditionalData },
        errors,
      } = response

      if (createReportAdditionalData) {
        dispatch('activityLog', {
          reportId: reportId,
          message: 'ACTIVITY_REPORT_ADD_ADDITIONAL_DATA',
          visible: false,
        })

        return { status: 'SUCCESS', createReportAdditionalData }
      } else {
        Logger.error(errors[0].message)
        commit('setError', 'Failed to create additional data')
        return { status: 'ERROR', errors }
      }
    } catch (error) {
      Logger.error(error.message)
      commit('setError', error.message)
      commit('setLoading', false)
      return false
    }
  },

  updateCriticalityLevel: async ({ dispatch }, params) => {
    const { reportId, customLevel, criticalLevelLabel } = params

    const payload = {
      reportId,
      customLevel,
    }

    try {
      const {
        data: { updateCriticalityLevel },
      } = await graphql.mutate({
        mutation: UPDATE_CRITICALITY_LEVEL,
        variables: payload,
      })

      await dispatch('activityLog', {
        reportId: reportId,
        message: 'ACTIVITY_REPORT_UPDATE_CRITICAL_LEVEL',
        visible: false,
        additionalInformation: [criticalLevelLabel],
      })

      return updateCriticalityLevel
    } catch (error) {
      Logger.error('Error updating criticality level', error)
      throw Error('ERROR_UPDATE_CRITICALITY_LEVEL')
    }
  },

  updateCompanyBranch: async ({ state, dispatch }, params) => {
    const { reportId, companyBranch } = params

    const payload = {
      reportId,
      companyBranch: companyBranch.key,
    }

    try {
      const {
        data: { updateCompanyBranch },
      } = await graphql.mutate({
        mutation: UPDATE_COMPANY_BRANCH_REPORT,
        variables: payload,
      })

      const locale = state.locale === 'pt-BR' ? 'pt' : state.locale
      await dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_UPDATE_COMPANY_BRANCH',
        visible: false,
        additionalInformation: [companyBranch[locale]],
      })

      return updateCompanyBranch
    } catch (error) {
      Logger.error("Error updating report's company branch", error)
      throw Error('ERROR_UPDATE_COMPANY_BRANCH')
    }
  },

  getAdditionalData: async ({ commit }, reportId) => {
    commit('setLoading', true)

    const {
      data: {
        reportByIdOnlyAdmin: { additionalData },
      },
    } = await graphql
      .query({
        query: GET_REPORT,
        variables: { id: reportId },
        fetchPolicy: 'no-cache',
      })
      .catch((err) => commit('setGraphQLError', err))
      .finally(() => commit('setLoading', false))

    return additionalData
  },

  assignReportAreas: async ({ commit, dispatch, state }, params) => {
    const { id, areas } = params

    const locale = state.locale === 'pt-BR' ? 'pt' : state.locale
    const areaNames = areas.map((area) => area[locale])
    const areaIds = areas.map((area) => area.id)

    const { data } = await graphql
      .mutate({
        mutation: ASSIGN_REPORT_AREAS,
        variables: { id, areaIds },
      })
      .catch((err) => commit('setGraphQLError', err))

    if (data) {
      dispatch('activityLog', {
        reportId: id,
        message: 'ACTIVITY_REPORT_AREA_ADDED',
        visible: false,
        additionalInformation: areaNames,
      })
    }

    return data.assignReportAreas
  },

  unassignReportAreas: async ({ commit, dispatch, state }, params) => {
    const { id, areas } = params

    const locale = state.locale === 'pt-BR' ? 'pt' : state.locale
    const areaNames = areas.map((area) => area[locale])
    const areaIds = areas.map((area) => area.id)

    const { data } = await graphql
      .mutate({
        mutation: UNASSIGN_REPORT_AREAS,
        variables: { id, areaIds },
      })
      .catch((err) => commit('setGraphQLError', err))

    if (data) {
      dispatch('activityLog', {
        reportId: id,
        message: 'ACTIVITY_REPORT_AREA_REMOVED',
        visible: false,
        additionalInformation: areaNames,
      })
    }

    return data.unassignReportAreas
  },

  attachReportInvolved: async ({ dispatch }, params) => {
    const { reportId, employeeId } = params

    const { data } = await graphql.mutate({
      mutation: ATTACH_REPORT_INVOLVED,
      variables: { reportId, employeeId },
    })

    if (data) {
      dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_INVOLVED_ADDED',
        visible: false,
        additionalInformation: [data.attachInvolvedToReport?.name],
      })
    }

    return data.attachInvolvedToReport
  },

  detachReportInvolved: async (
    { dispatch },
    { reportId, employeeName, involvedId },
  ) => {
    const { data } = await graphql.mutate({
      mutation: DETACH_REPORT_INVOLVED,
      variables: { involvedId },
    })

    if (data) {
      dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_INVOLVED_REMOVED',
        visible: false,
        additionalInformation: [employeeName],
      })
    }

    return data.detachInvolvedFromReport
  },

  moveReportsToGroups: async ({ dispatch }, reportGroups) => {
    try {
      const { reportId } = reportGroups

      const { data } = await graphql.mutate({
        mutation: MOVE_REPORTS_TO_GROUPS,
        variables: { reportGroups: reportGroups },
      })

      await dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_MOVED_GROUP',
        visible: false,
        additionalInformation: data.moveReportsToGroups,
      })

      return data.moveReportsToGroups
    } catch (error) {
      Logger.error('Error moving reports to groups', error)
      throw Error('ERROR_MOVE_REPORTS_TO_GROUPS')
    }
  },

  removeReportFromGroup: async ({ dispatch }, reportGroups) => {
    try {
      const { reportId } = reportGroups

      const { data } = await graphql.mutate({
        mutation: REMOVE_REPORTS_FROM_GROUPS,
        variables: { reportGroups: reportGroups },
      })

      await dispatch('activityLog', {
        reportId,
        message: 'ACTIVITY_REPORT_REMOVED_GROUP',
        visible: false,
        additionalInformation: data.removeReportFromGroups,
      })
    } catch (error) {
      Logger.error('Error removing reports from groups', error)
      throw Error('ERROR_REMOVE_REPORTS_TO_GROUPS')
    }
  },
}
